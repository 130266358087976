import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { Observable, of } from 'rxjs';
import { AbstractRestoreDiagram } from './abstract-restore-diagram.cmd';

/**
 * This is the command to restore a given snapshot
 *
 * data: {
 *     diagramId id of the resource
 *     lastChangeId lastChangeId of the snapshot
 * }
 */
@Injectable()
@Command()
export class RestoreDiagram extends AbstractRestoreDiagram {

    protected fetchTargetDoc(): Observable<any> {
        return of( this.data.targetDoc );
    }
}

Object.defineProperty( RestoreDiagram, 'name', {
    value: 'RestoreDiagram',
});

import { Observable ,  Subscription ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Logger } from './../logger/logger.svc';
import { isEqual } from 'lodash';
import { Flags } from '../flags';
import { IStateService } from 'flux-definition';
import { EventCollector, EventIdentifier } from '../logger/event-collector.svc';
import { JsonUtil } from '../data/json-util';
/**
 * This is a service that lets you manage application wide state.
 * If you need to manage a state in your app that may be utilized by more than
 * one component or page, then you should consider using this service to manage
 * the said state.
 *
 * The service is designed to manage mutiple global states that are each bound to
 * some type of data. The state can be changed by any component/service that injects this
 * service. Each state can be listen to for change as well by anyone.
 *
 * To centrally manage the state there will be a command type. This must be used to so
 * other aspects also can be managed as part of changing state.
 *
 * @author  hiraash
 * @since   2017-07-03
 */
@Injectable()
export class StateService<K, V> implements IStateService< K, V> {

    /**
     * stores each state by the given unique identifier as the key
     * and a value of any type for that key. The key must be unique
     * across the application scope.
     */
    protected _states: Map<K, V>;

    /**
     * stores the observable for each state that emits the state
     * changes. Not every state has a associated observable. they
     * are created on demand.
     */
    protected _changes: Map<K, BehaviorSubject<V>>;

    /**
     * Stores listners for each state that are active right now.
     * The state service can listen to changes of a state through a
     * observable type. The subscriptions that are active are stored
     * in this map. One state can only have one subscription at any given
     * time. If an external value directly sets the state also the subscription
     * will be cancelled.
     */
    protected _listners: Map<K, Subscription>;

    /**
     * Stores the list of states that are shared states. This is so that the
     * state service or external parties know which of the current states are
     * shared states. Shared states are states that are sent across to other active
     * clients.
     */
    protected _shared: Set<K>;

    private blacklisted = [
        'CurrentInteractionHandler',
        'ShapeViews',
        'InteractionCanvas',
        'GridAreaCanvas',
        'GridAreaCanvas',
        'DiagramCanvas',
        'CurrentSelection',
    ];

    private previewChecks = [
        ( stateType: any ) => [ 'PreviewChanges', 'PreviewChangesConnections', 'SnapData' ].includes( stateType ),
        ( stateType: any, state: any ) => stateType === 'SelectionInteraction',
    ];

    /**
     * Constructor. Initializes the dictionaries used by the
     * state service.
     */
    constructor( protected log: Logger ) {
        this._states = new Map();
        this._changes = new Map();
        this._listners = new Map();
        this._shared = new Set();
    }

    /**
     * This function initializes a given state type. It can be thought of as
     * a default or starting value for a state. The state set via this method
     * does not trigger a change.
     * @param stateType an identifier for the state type. This must be unique to the
     *      application scope and can be of any type.
     * @param state The state data of any data type that will be set as the current state
     */
    public initialize( stateType: K, state: V, shared: boolean = false ) {
        this.setState( stateType, state, shared );
    }

    /**
     * Sets the given state to the state type. You can also use this
     * to define/register a new state.
     * A change is triggered after setting the state.
     * @param stateType an identifier for the state type. This must be unique to the
     *      application scope and can be of any type.
     * @param state The state data of any data type that will be set as the current state
     * @param stopListening Boolean to indicate if the service should stop listening to any changes it
     *      is currently listening to for this stateType. This is true by defult. Setting this to false
     *      may cause unexpected behaviour and even may overide the current value being set.
     */
    public set( stateType: K, state: V, shared: boolean = false, stopListening: boolean = true ) {
        if ( stopListening ) {
            this.stopListening( stateType );
        }

        this.setState( stateType, state, shared );

        if ( this._changes.has( stateType )) {
            this._changes.get( stateType ).next( state );
        }
    }

    /**
     * Listens to changes on the given observable and sets the state with the emitted value.
     * This will stop listening to any previous observables before starting. Only one listner
     * will be active at any given time. Listners will also be stopped if a state is directly
     * changed by setting it.
     * @param stateType an identifier for the state type. This must be unique to the
     *      application scope and can be of any type.
     * @param changes An observable that emits the state value for this state type.
     *      If the observable emits multiple values, the state will keep changing.
     */
    public listen( stateType: K, changes: Observable<V> ) {
        this.stopListening( stateType );

        const subs = changes.subscribe({
            next: state => this.set( stateType, state, false, false ),
            complete: () => this.stopListening( stateType ),
        });
        this._listners.set( stateType, subs );

    }

    /**
     * Returns the current state for the given state type.
     * @param stateType an identifier for the state type
     */
    public get( stateType: K ): V {
        return this._states.get( stateType );
    }

    /**
     * Returns the set of states that are shared states. Not
     * All states are shared. Shared states are what are collaborated
     * with users who are currently online. The keys returned here
     * are the only ones collaborated.
     */
    public getShared(): K[] {
        return Array.from( this._shared );
    }

    /**
     * Indicates if the given state type is registered or exists
     * @param stateType an identifier for the state type
     */
    public has( stateType: K ): boolean {
        return this._states.has( stateType );
    }

    /**
     * This is a method to check if the currently set state matches ANY one
     * of the of states passed in.
     * @param stateType an identifier for the state type
     * @param states arbitrary number of states to check for
     */
    public is( stateType: K, ...states: V[]): boolean {
        if ( !this.has( stateType )) {
            return false;
        }
        for ( const state of states ) {
            if ( isEqual( this._states.get( stateType ), state )) {
                return true;
            }
        }
        return false;
    }

    /**
     * This function checks if the currently set state is not ANY of the states passed
     * in.
     * @param stateType an identifier for the state type
     * @param states arbitrary number of states to check for
     */
    public isNot( stateType: K, ...states: V[]): boolean {
        return !this.is( stateType, ...states );
    }

    /**
     * Returns a subject that will emit changes to the given state
     * The subject is only created on request. If this method is never call
     * a subject for the given state type is never created.
     * @param stateType an identifier for the state type
     */
    public changes( stateType: K ): Observable<V> {
        if ( !this.has( stateType )) {
            this.log.info(
                `State changes requested on a stateType (${stateType}) that is currently ` +
                'unavailable but it may be available in future.',
            );
        }
        if ( !this._changes.has( stateType )) {
            this._changes.set( stateType, new BehaviorSubject( this.get( stateType )));
        }
        return this._changes.get( stateType );
    }

    /**
     * Removes the given state and completes the change stream bound to it. All
     * nodes listening to the state will complete.
     * @param stateType an identifier for the state type which already exists
     */
    public remove( stateType: K ) {
        if ( !this.has( stateType )) {
            throw new Error( 'Requested state type is not set or does not exist' );
        }

        this._states.delete( stateType );
        if ( this._changes.has( stateType )) {
            this._changes.get( stateType ).complete();
            this._changes.delete( stateType );
        }
        if ( this._shared.has( stateType )) {
            this._shared.delete( stateType );
        }
    }

    /**
     * Stops listening to the current observable if any.
     * @param stateType identifier for the state.
     */
    protected stopListening( stateType: K ) {
        if ( this._listners.has( stateType )) {
            this._listners.get( stateType ).unsubscribe();
            this._listners.delete( stateType );
        }
    }

    /**
     * This function validates the state type and the given state, and sets the state
     * passed into the method as the current state.
     * @param stateType an identifier for the state type. This must be unique to the
     *      application scope and can be of any type.
     * @param state The state data of any data type that will be set as the current state
     */
    protected setState( stateType: K, state: V, shared: boolean ) {
        if ( Flags.get( 'DEBUG_STATE_CHANGES' )) {
            Logger.debug( `StateService: changing state`, stateType, state );
        }
        const logChange = Flags.get( 'LOG_COLLECTOR_ENABLED' ) && !this.blacklisted.includes( stateType as any )
            && ( Flags.get( 'LOG_PREVIEW_CHANGES' ) || !this.previewChecks.some( c => c( stateType, state )));
        if ( logChange ) {
            EventCollector.log({
                message: EventIdentifier.STATE_CHANGED,
                stateType,
                state: typeof state === 'object' ? JsonUtil.clone( state ) : state,
                shared,
            });
        }
        if ( !stateType ) {
            throw new Error( 'Provide a valid identifier for the state which is unique.' );
        }
        if ( state === undefined || state === null ) {
            throw new Error( 'Provide a valid value for the state.' );
        }

        this._states.set( stateType, state );
        if ( shared ) {
            this._shared.add( stateType );
        }
    }


}

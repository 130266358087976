import { Injectable } from '@angular/core';
import { Proxied } from '@creately/sakota';
import { Command } from 'flux-core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { DiagramDiffService } from '../../../base/diagram/diagram-diff.svc';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * This is the command to restore a given snapshot
 *
 * data: {
 *     diagramId id of the resource
 *     lastChangeId lastChangeId of the snapshot
 * }
 */
@Injectable()
@Command()
export abstract class AbstractRestoreDiagram extends AbstractDiagramChangeCommand {

    public static ignoreFields = [
        // dynamic fields
        'lastChangeId',
        'lastUpdated',
        'updatedBy',
        // static fields
        'id',
        'project',
        'collabs',
        'teamShare',
        'privacy',
        'createdTime',
        'thumbnailUrl',
        // below field was saved in db due to a bug.
        'undoGluepointLockQuery',
    ];

    constructor(
        protected ds: DiagramChangeService,
        protected dds: DiagramDiffService ) {
        super( ds )/* istanbul ignore next */;
    }

    /**
     * Returns an observable which emits the proxied diagram model once.
     */
    public prepareProxy(): Observable<Proxied<DiagramModel>> {
        return this.ds.getRawChangeModel( this.resourceId, this.eventData.eventId );
    }

    public prepareData(): Observable<any> {
        return this.fetchTargetDoc().pipe(
            tap( snapshot => {
                this.dds.applyChanges( this.changeModel, snapshot );
                AbstractRestoreDiagram.ignoreFields.forEach( prop => this.changeModel.__sakota__.reset( prop ));
            }),
        );
    }

    public execute() {
        this.resultData = { skipChangeBinders: true };
        return true;
    }

    protected abstract fetchTargetDoc(): Observable<any>;
}

// Object.defineProperty( RestoreDiagramSnapshot, 'name', {
//     value: 'RestoreDiagramSnapshot',
// });

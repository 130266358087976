import { Injectable } from '@angular/core';
import { EventCollector, EventIdentifier } from '../logger/event-collector.svc';
@Injectable()
export class Random {

    // tslint:disable:member-ordering
    /**
     * sessionId
     * sessionId returns a new randomly generated project id.
     */
    public static sessionId() {
        return this.base62( Random.SESSION_ID_SIZE, 'sessionId' );
    }

    /**
     * projectId
     * projectId returns a new randomly generated project id.
     */
    public static projectId() {
        return this.base62( Random.PROJECT_ID_SIZE, 'projectId' );
    }

    /**
     * eventId
     * eventId returns a new randomly generated event id.
     */
    public static eventId( ctx: any = {}) {
        return this.base62( Random.EVENT_ID_SIZE, 'eventId', ctx );
    }

    /**
     * dataItemId
     * dataItemId returns a new randomly generated dataItemId.
     */
    public static dataItemId() {
        return this.base62( Random.DATAITEM_ID_SIZE, 'dataItemId' );
    }

    /**
     * dataItemId
     * dataItemId returns a new randomly generated dataItemId.
     */
    public static attachmentId() {
        return this.base62( Random.ATTACHMENT_ID_SIZE, 'attachmentId' );
    }

    /**
     * dataItemId
     * dataItemId returns a new randomly generated dataItemId.
     */
    public static peopleId() {
        return this.base62( Random.DATAITEM_ID_SIZE, 'peopleId' );
    }

    /**
     * votingSessionId
     * votingSessionId returns a new randomly generated voting session id.
     */
    public static votingSessionId() {
        return this.base62( Random.VOTING_SESSION_ID_SIZE, 'votingSessionId' );
    }

    /**
     * diagramId
     * diagramId returns a new randomly generated diagram id.
     */
    public static diagramId() {
        return this.base62( Random.DIAGRAM_ID_SIZE, 'diagramId' );
    }

    /**
     * eDataId
     * eDataId returns a new randomly generated edata id.
     */
    public static eDataId() {
        return this.base62( Random.EDATA_ID_SIZE, 'eDataId' );
    }

    /**
     * changeId
     * changeId returns a new randomly generated change id.
     */
    public static changeId() {
        return this.base62( Random.CHANGE_ID_SIZE, 'changeId' );
    }

    /**
     * shapeId
     * shapeId returns a new randomly generated shape id.
     */
    public static shapeId() {
        return this.base62( Random.SHAPE_ID_SIZE, 'shapeId' );
    }

    /**
     * connectionId
     * connectionId returns a new randomly generated connection id.
     */
    public static connectionId() {
        return this.base62( Random.CONNECTION_ID_SIZE, 'connectionId' );
    }

    /**
     * pointId
     * pointId returns a new randomly generated point id.
     */
    public static pointId() {
        return this.base62( Random.POINT_ID_SIZE, 'pointId' );
    }

    /**
     * groupId
     * groupId returns a new randomly generated group id.
     */
    public static groupId() {
        return this.base62( Random.GROUP_ID_SIZE, 'groupId' );
    }

    /**
     * gluepointId
     * gluepointId returns a new randomly generated group id.
     */
    public static gluepointId() {
        return this.base62( Random.GLUEPOINT_ID_SIZE, 'gluepointId' );
    }

    /**
     * textId
     * pointId returns a new randomly generated text id.
     */
    public static textId() {
        return this.base36( Random.TEXT_ID_SIZE, 'textId' );
    }

    /**
     * commentId
     * commentId returns a new randomly generated comment id.
     */
    public static commentId() {
        return this.base62( Random.COMMENT_ID_SIZE, 'commentId' );
    }

    /**
     * taskId
     * taskId returns a new randomly generated comment id.
     */
    public static taskId() {
        return this.base62( Random.COMMENT_ID_SIZE, 'taskId' );
    }


    /**
     * entityId
     * entityId returns a new randomly generated entity id.
     */
    public static entityId() {
        return this.base62( Random.ENTITY_ID_SIZE, 'entityId' );
    }

    /**
     * entityListId
     * entityListId returns a new randomly generated entityList id.
     */
    public static entityListId() {
        return this.base62( Random.ENTITY_LIST_ID_SIZE );
    }

    /**
     * linkId
     * linkId returns a new randomly generated link id.
     */
    public static linkId() {
        return this.base62( Random.LINK_ID_SIZE, 'linkId' );
    }

    /**
     * ruleId
     * ruleId returns a new randomly generated ruleId.
     */
     public static ruleId() {
        return this.base62( Random.RULE_ID_SIZE, 'ruleId' );
    }

    /**
     * teamShareId
     * teamShareId returns a new randomly generated teamShare id.
     */
    public static teamShareId() {
        return this.base62( Random.TEAM_SHARE_ID_SIZE );
    }

    /**
     * changeId
     * changeId returns a new randomly generated change id.
     */
    public static shareHash() {
        return this.base62( Random.SHARE_HASH_SIZE );
    }

    public static presentationId() {
        return this.base62( Random.PRESENTATION_ID_SIZE );
    }

    public static slideId() {
        return this.base62( Random.SLIDE_ID_SIZE );
    }

    /**
     * This function returns a deterministic id based on 2 random ids.
     * @param id string
     * @param baseId string
     */
    public static getIdDiff( id: string, baseId: string ) {
        if ( id.length > baseId.length ) {
            throw new Error( 'id and base id should be same length' );
        }
        return Array.from( id )
            .map(( c, idx ) => this._BASE62[c] - this._BASE62[baseId[idx]])
            .map( c => this.BASE62[c < 0 ? -c : c]).join( '' );
    }

    /**
     * alphabet
     * alphabet is an array or characters used by the base62 encoder.
     */
    protected static BASE62 = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split( '' );
    protected static _BASE62 = Random.BASE62.reduce(( acc, val, indx ) => {
        acc[val] = indx;
        return acc;
    }, {});

    /**
     * buffer
     * buffer is used to generate random data needed to generate ids.
     * NOTE: increase this value if there are ids longer than 22 chars.
     */
    protected static buffer = new Uint8Array( 22 );

    /**
     * DIAGRAM_ID_SIZE
     * DIAGRAM_ID_SIZE is the number of characters on a diagram id.
     */
    protected static DIAGRAM_ID_SIZE = 11;

    /**
     * VOTING_SESSION_ID_SIZE
     * VOTING_SESSION_ID_SIZE is the number of characters on a voting session id.
     */
    protected static VOTING_SESSION_ID_SIZE = 11;

    /**
     * EDATA_ID_SIZE
     * EDATA_ID_SIZE is the number of characters on a diagram id.
     */
    protected static EDATA_ID_SIZE = 11;

    /**
     * SESSION_ID_SIZE
     * SESSION_ID_SIZE is the number of characters on a SESSION id.
     */
    protected static SESSION_ID_SIZE = 11;

    /**
     * PROJECT_ID_SIZE
     * PROJECT_ID_SIZE is the number of characters on a project id.
     */
    protected static PROJECT_ID_SIZE = 11;

    /**
     * EVENT_ID_SIZE
     * EVENT_ID_SIZE is the number of characters on an event id.
     */
    protected static EVENT_ID_SIZE = 22;

    /**
     * CHANGE_ID_SIZE
     * CHANGE_ID_SIZE is the number of characters on a change id.
     */
    protected static CHANGE_ID_SIZE = 22;

    /**
     * SHAPE_ID_SIZE
     * SHAPE_ID_SIZE is the number of characters on a shape id.
     */
    protected static SHAPE_ID_SIZE = 11;

    /**
     * CONNECTION_ID_SIZE
     * CONNECTION_ID_SIZE is the number of characters on a point id.
     */
    protected static CONNECTION_ID_SIZE = 11;

    /**
     * POINT_ID_SIZE
     * POINT_ID_SIZE is the number of characters on a point id.
     */
    protected static POINT_ID_SIZE = 11;

    /**
     * GROUP_ID_SIZE
     * GROUP_ID_SIZE is the number of characters on a group id.
     */
    protected static GROUP_ID_SIZE = 11;

    /**
     * GLUEPOINT_ID_SIZE
     * GLUEPOINT_ID_SIZE is the number of characters on a gluepoint id.
     */
    protected static GLUEPOINT_ID_SIZE = 11;

    /**
     * TEXT_ID_SIZE
     * TEXT_ID_SIZE is the number of characters on a text id.
     */
    protected static TEXT_ID_SIZE = 11;

    /**
     * COMMENT_ID_SIZE
     * COMMENT_ID_SIZE is the number of characters on a comment id.
     */
    protected static COMMENT_ID_SIZE = 11;


    /**
     * TASK_ID_SIZE
     * TASK_ID_SIZE is the number of characters on a comment id.
     */
    protected static TASK_ID_SIZE = 11;

    /**
     * ENTITY_ID_SIZE
     * ENTITY_ID_SIZE is the number of characters on a ENTITY id.
     */
    protected static ENTITY_ID_SIZE = 11;

    /**
     * ENTITY_LIST_ID_SIZE
     * ENTITY_LIST_ID_SIZE is the number of characters on a ENTITY_LIST id.
     */
    protected static ENTITY_LIST_ID_SIZE = 11;

    /**
     * LINK_ID_SIZE
     * LINK_ID_SIZE is the number of characters on a LINK id.
     */
    protected static LINK_ID_SIZE = 8;


    /**
     * RULE_ID_SIZE
     * RULE_ID_SIZE is the number of characters on a LINK id.
     */
     protected static RULE_ID_SIZE = 8;


    /**
     * DATAITEM_ID_SIZE
     * DATAITEM_ID_SIZE is the number of characters on an data item id.
     */
    protected static DATAITEM_ID_SIZE = 11;

    /**
     * ATTACHMENT_ID_SIZE
     * ATTACHMENT_ID_SIZE is the number of characters on an data item id.
     */
    protected static ATTACHMENT_ID_SIZE = 11;

    /**
     * PEOPLE_ID_SIZE
     * PEOPLE_ID_SIZE is the number of characters on an data item id.
     */
    protected static PEOPLE_ID_SIZE = 11;

    /**
     * TEAM_SHARE_ID_SIZE
     * TEAM_SHARE_ID_SIZE is the number of characters on a teamSHare permission id.
     */
    protected static TEAM_SHARE_ID_SIZE = 11;

    /**
     * SHARE_HASH_SIZE
     * SHARE_HASH_SIZE is the number of characters on a share hash.
     */
    protected static SHARE_HASH_SIZE = 22;

    /**
     * PRESENTATION_ID_SIZE
     * PRESENTATION_ID_SIZE is the number of characters for a presentation ID.
     */
    protected static PRESENTATION_ID_SIZE = 11;

    /**
     * SLIDE_ID_SIZE
     */
    protected static SLIDE_ID_SIZE = 11;

    /**
     * base62
     * base62 generates a random base62 string with given size.
     * It will use the web crypto API to generate random bytes.
     */
    public static base62( size: number, callerName = null, ctx: any = {}): string {
        const bytes = crypto.getRandomValues( Random.buffer );
        const chars = [];
        for ( let i = 0; i < size; ++i ) {
            chars[i] = Random.BASE62[bytes[i] % 62];
        }
        const val = chars.join( '' );
        EventCollector.log({
            message: EventIdentifier.RANDOM_ID_GENERATED,
            type: 'base62',
            value: val,
            caller: callerName,
            ...ctx,
        });
        return val;
    }

    /**
     * base36
     * base36 generates a random base36 string with given size.
     * It will use the Math.radom to generate random number.
     * @param size Size of the id required.
     */
    protected static base36( size: number, callerName = null ): string {
        let id = '';
        while ( id.length < size ) {
            id += Math.random().toString( 36 ).slice( 2 );
        }
        const val = id.slice( 0, size );
        EventCollector.log({
            message: EventIdentifier.RANDOM_ID_GENERATED,
            type: 'base36',
            value: val,
            caller: callerName,
        });
        return val;
    }

    /**
     * projectId
     * projectId returns a new randomly generated project id.
     */
    public projectId(): string {
        return Random.projectId();
    }

    /**
     * diagramId
     * diagramId returns a new randomly generated diagram id.
     */
    public diagramId(): string {
        return Random.diagramId();
    }

    /**
     * eventId
     * eventId returns a new randomly generated event id.
     */
    public eventId(): string {
        return Random.eventId();
    }

    /**
     * changeId
     * changeId returns a new randomly generated change id.
     */
    public changeId(): string {
        return Random.changeId();
    }

    /**
     * shapeId
     * shapeId returns a new randomly generated shape id.
     */
    public shapeId(): string {
        return Random.shapeId();
    }

    /**
     * connectionId
     * connectionId returns a new randomly generated connection id.
     */
    public connectionId(): string {
        return Random.connectionId();
    }

    /**
     * pointId
     * pointId returns a new randomly generated point id.
     */
    public pointId(): string {
        return Random.pointId();
    }

    /**
     * textId
     * textId returns a new randomly generated text id.
     */
    public textId(): string {
        return Random.textId();
    }

    /**
     * groupId
     * groupId returns a new randomly generated group id.
     */
    public groupId(): string {
        return Random.groupId();
    }

    /**
     * gluepointId
     * gluepointId returns a new randomly generated gluepoint id.
     */
    public gluepointId(): string {
        return Random.gluepointId();
    }

    /**
     * commentId
     * commentId returns a new randomly generated comment id.
     */
    public commentId(): string {
        return Random.commentId();
    }

    /**
     * teamShareId
     * teamShareId returns a new randomly generated comment id.
     */
    public teamShareId(): string {
        return Random.teamShareId();
    }

    /**
     * shareHash
     * shareHash returns a new randomly generated share hash.
     */
    public shareHash(): string {
        return Random.shareHash();
    }
}

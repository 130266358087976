import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { Observable } from 'rxjs';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { DiagramDiffService } from '../../../base/diagram/diagram-diff.svc';
import { DiagramHistoryService } from '../../../base/diagram/diagram-history.svc';
import { AbstractRestoreDiagram } from './abstract-restore-diagram.cmd';

/**
 * This is the command to restore a given snapshot
 *
 * data: {
 *     diagramId id of the resource
 *     lastChangeId lastChangeId of the snapshot
 * }
 */
@Injectable()
@Command()
export class RestoreDiagramSnapshot extends AbstractRestoreDiagram {

    public static get dataDefinition(): {}  {
        return {
            diagramId: true,
            lastChangeId: true,
        };
    }

    constructor(
        protected ds: DiagramChangeService,
        protected dhs: DiagramHistoryService,
        protected dds: DiagramDiffService ) {
        super( ds, dds )/* istanbul ignore next */;
    }

    protected fetchTargetDoc(): Observable<any> {
        const { diagramId, lastChangeId } = this.data;
        return this.dhs.fetchRawSnapshot( diagramId, lastChangeId );
    }
}

Object.defineProperty( RestoreDiagramSnapshot, 'name', {
    value: 'RestoreDiagramSnapshot',
});

import { Flags } from '../flags';

export enum EventIdentifier {
    COMMAND_EVENT_STARTED = 'command event started',
    COMMAND_EVENT_QUEUED = 'command event queued',
    COMMAND_EVENT_COMPLETED = 'command event completed',
    COMMAND_EVENT_ERRORED = 'command event errored',
    EDATA_LOCATOR_CHANGE_PROCESSED = 'edata locator change processed',
    SHAPE_DATA_ITEM_UPDATED = 'shape data items updated',
    SHAPE_DATA_EDITOR_PANELS = 'shape data editor panels',
    EDATA_PANELS_UPDATED = 'edata panels updated',
    COMMAND_UNMAPPED_QUEUED = 'command unmapped queued',
    STATE_CHANGED = 'state changed',
    DIAGRAM_PREVIEW_LOCATOR_CHANGE_PROCESSED = 'diagram preview locator change processed',
    DIAGRAM_STORED_LOCATOR_CHANGE_PROCESSED = 'diagram stored locator change processed',
    STORAGE_ADDED = 'storage insert',
    STORAGE_UPDATED = 'storage updated',
    STORAGE_REMOVED = 'storage removed',
    STORAGE_STATIC_ADDED = 'storage static insert',
    STORAGE_STATIC_UPDATED = 'storage static updated',
    STORAGE_STATIC_REMOVED = 'storage static removed',
    STORAGE_HISTORY_METADATA_ADDED = 'storage history metadata insert',
    STORAGE_HISTORY_METADATA_UPDATED = 'storage history metadata updated',
    STORAGE_CHANGE_ADDED = 'storage change insert',
    STORAGE_CHANGE_REMOVED = 'storage change removed',
    RANDOM_ID_GENERATED = 'random id generated',
    RANDOM_VALUE_GENERATED = 'random value generated',
    TIPTAP_EDITOR_CONTENT = 'tiptap editor content',
    ConvertViewportForTransform_RESULT = 'ConvertViewportForTransform result',
    ConvertViewportForPoints_RESULT = 'ConvertViewportForPoints result',
    PLUS_CREATE_EVENT_DISPATCHED = 'PlusCreate event dispatched',
    SHAPE_ADDED = 'shape added',
    TEMPLATE_ADDED = 'template added',
    SHAPE_CREATED = 'shape created',
}

export interface IEventLog {
    message: EventIdentifier;
    ts: number;
    [k: string]: any;
}

export class EventCollector {
    public static get instance(): EventCollector {
        if ( !this._instance ) {
            this._instance = new EventCollector();
        }
        return this._instance;
    }

    public static log( event: Omit<IEventLog, 'ts'> ) {
        if ( Flags.get( 'LOG_COLLECTOR_ENABLED' )) {
            this.instance.collect( event );
        }
    }

    public static dump( fileName: string = 'debug' ) {
        this.instance.export( fileName );
    }

    private static _instance;

    private events: IEventLog[] = [];

    private constructor() {}

    public collect( event: Omit<IEventLog, 'ts'> ) {
        this.events.push({
            ...event, ts: Date.now(),
        } as IEventLog );
    }

    public clearLogs() {
        this.events = [];
    }

    print( start?: number, end?: number ) {
        start = start || 0;
        end = end || this.events.length;
        // tslint:disable-next-line:no-console
        console.log( this.events.slice( start, end ));
    }

    export( fileName: string = 'debug' ) {
        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent( JSON.stringify( this.events ));
        const link = document.createElement( 'a' );
        link.setAttribute( 'href', dataStr );
        link.setAttribute( 'download', `${fileName}.json` );
        document.body.appendChild( link );
        link.click();
        document.body.removeChild( link );
    }
}

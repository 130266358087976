import { AbstractShapeModel, splitModifier } from 'flux-diagram-composer';
import { TiptapEditor } from '../../../framework/ui/components/tiptap/tiptap-editor.cmp';
import { DynamicComponent } from 'flux-core/src/ui';
import { CommandService, IModifier, StateService } from 'flux-core';
import { DiagramToViewportCoordinate } from '../../coordinate/diagram-to-viewport-coordinate.svc';
import { filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { AfterViewInit, OnDestroy, Component, ChangeDetectionStrategy, ComponentFactoryResolver,
    ViewChild, ViewContainerRef, Injector, ComponentRef, ElementRef } from '@angular/core';
import { merge, of, Subscription, Observable, fromEvent } from 'rxjs';
import { Editor, getHTMLFromFragment } from '@tiptap/core';
import { DiagramCommandEvent } from '../../../editor/diagram/command/diagram-command-event';
import { TiptapHelper } from '../../../framework/ui/components/tiptap/tiptap-helper';
import { KeyCode } from 'flux-definition';
import { getTextDirection } from '../../../framework/ui/components/tiptap/custom-extensions/text-direction';

// tslint:disable:member-ordering
/**
 * TiptapDocumentsManager
 *
 * This component creates a tiptap document per document as the TiptapDocument
 * state changes. TiptapDocument state can be set from any where in the app to
 * request this component to create a new tiptap doc and a `child editor node view` for the shapeId
 * e.g. { diagramId: string, shapeId?: string }
 *
 * FIXME:
 * Destruction of the tip editors is not done since thre's no exact point.
 * Please look into this further.
 * @author thisun
 * @since 2022 - 06 - 29
 */

@Component({
    template: `<div #viewContainer></div>`,
    selector: 'tiptap-documents-manager',
    styleUrls: [ './tiptap-documents-manager.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiptapDocumentsManager extends DynamicComponent implements AfterViewInit, OnDestroy {

    public static documents: { [diagramId: string]: ComponentRef<TiptapEditor> } = {};

    protected subs: Subscription[] = [];

    @ViewChild( 'viewContainer', { read: ViewContainerRef, static: false })
    protected viewContainer: ViewContainerRef;

    constructor (
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected dtov: DiagramToViewportCoordinate,
        protected ll: DiagramLocatorLocator,
        protected er: ElementRef,
        protected commandSvc: CommandService,
        protected state: StateService<any, any> ) {
            super( componentFactoryResolver, injector );
    }

    public getDocuments() {
        return TiptapDocumentsManager.documents;
    }

    public ngAfterViewInit() {
        this.subs.push(
            this.state.changes( 'CurrentDiagram' ).pipe(
                switchMap( diagramId => this.state.changes( 'TiptapDocument' ).pipe(
                    startWith({ diagramId }),
                    filter( data => data.diagramId && !data.edataId && !data.entityId ),
                    switchMap( data => this.manageDocument( data.diagramId, data.shapeId )),
                )),
            ).subscribe(),
            this.state.changes( 'CurrentDiagram' ).pipe(
                switchMap( diagramId => this.manageShapeDeletes()),
            ).subscribe(),
        );
    }

    protected getEditor( diagramId ): Observable<ComponentRef<TiptapEditor>> {
        const cmpRef = this.getDocuments()[ diagramId ];
        if ( !cmpRef ) {
            // test automation was breaking if we wait till component initialization.
            return this.createTiptap( diagramId, false ).pipe(
                tap( cr => {
                    this.getDocuments()[ diagramId ] = cr;
                }),
                switchMap( cr => this.waitForInitTiptap( cr )),
            );
        }
        return of( cmpRef );
    }

    /**
     * Creates a tiptap editor for the diagram if not already created,
     * If the shapeId is given, adds a child editor node view for that shape
     * @param diagramId
     * @param shapeId
     */
    /**
     * Creates a tiptap editor for the diagram if not already created,
     * If the shapeId is given, adds a child editor node view for that shape
     * @param diagramId
     * @param shapeId
     */
     public manageDocument( diagramId: string, shapeId?: string ) {
        if ( !shapeId ) {
            return of({});
        }
        return this.getEditor( diagramId ).pipe( switchMap( cmpRef => {
            cmpRef.instance.shapeIdSubject.next( shapeId );
            const editor = cmpRef.instance.editorSubject.value;
            return this.ll.forDiagram( diagramId, false ).getDiagramOnce().pipe(
                switchMap( diagram => {
                    const shape: any = diagram.shapes[ shapeId ];
                    const initialized: HTMLElement = document.querySelector(
                        `tiptap-editor-component[id="${diagramId}"].notes div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"]`,
                    );
                    const dataItems = shape.getDataItems( diagram );
                    if ( !initialized && dataItems.description && typeof dataItems.description.value === 'string' ) {
                            const value = dataItems.description.value || '<p></p>';
                            const { content } = this.preparePrimaryTextNode( shape, editor );
                            editor.chain()
                                .focus()
                                .setTextSelection( editor.state.doc.content.size )
                                .insertContent( `<tiptap-child-editor-node shapeid="${ shapeId }" >${content}${ value }</tiptap-child-editor-node>` )
                                .blur()
                                .run();
                            this.cleanUpDocument( diagramId );
                            const { from } = TiptapDocumentsManager.getPrimaryTextNode( diagramId, shapeId );

                            setTimeout(() => {
                                if ( from !== undefined ) {
                                    editor.chain()
                                    .focus()
                                    .setTextSelection( from + 1 )
                                    .run();
                                }
                            }, 10 );
                    } else if ( initialized && !initialized.innerText.trim()) {
                        this.updatePrimaryTextNode( diagramId, shape );
                        const { from } = TiptapDocumentsManager.getPrimaryTextNode( diagramId, shapeId );
                        if ( from !== undefined ) {
                            editor.commands.setTextSelection( from + 1 );
                        }
                    } else {
                        this.updatePrimaryTextNode( diagramId, shape );
                    }
                  // NOTE: There's a chance of getting text editor nodes duplicated for a single
                    // shape when collab editing. ( e.g. 2 collabs try to add notes to the
                    // same shape at the same time ) This deletes the most recent duplicate.
                    setTimeout(() => {
                        TiptapDocumentsManager.removeDuplicates( diagramId, shapeId );
                    }, 1000 );
                    return of({});
                }),
            );
        }));
    }

    /**
     * This method prpends a new tiptap primary text node into the child text editor node view
     * if such node is not present
     */
    protected insertPrimaryTextNode( diagramId: string, shapeModel: AbstractShapeModel ) {
        const { domNode, from, editor } = TiptapDocumentsManager.getChildEditorNode( diagramId, shapeModel.id );
        if ( !domNode || !from || !editor ) {
            return;
        }
        const { content } = this.preparePrimaryTextNode( shapeModel, editor );
        if (( domNode as any )?.firstChild?.firstChild?.getAttribute( 'data-type' ) !==  'primaryTextNode' ) {
            editor.chain()
                .focus()
                .setTextSelection( from )
                .insertContent( content )
                .blur()
                .run();
        }
    }

    protected updatePrimaryTextNode( diagramId, shapeModel ) {
        const { domNode, from, editor } = TiptapDocumentsManager.getChildEditorNode( diagramId, shapeModel.id );
        const textModel = shapeModel.primaryTextModel;
        if ( !textModel ) {
            return;
        }
        if (( domNode as any )?.firstChild?.firstChild?.getAttribute( 'data-type' ) ===  'primaryTextNode' ) {
            const { details, content } = this.preparePrimaryTextNode( shapeModel, editor );
            const val = TiptapDocumentsManager.getPrimaryTextNode( diagramId, shapeModel.id );
            const html = val.domNode.querySelector( 'summary' ).innerHTML;
            if ( html !== textModel.plainText || details !== '<p></p>' ) {
                editor.chain()
                    .focus()
                    .setTextSelection( from + 1 )
                    .deleteNode( 'primaryTextNode' ).run();
                editor.chain().setTextSelection( from )
                    .insertContent( content )
                    .blur()
                    .run();
            }
        } else {
            this.insertPrimaryTextNode( diagramId, shapeModel );
        }
    }


    protected preparePrimaryTextNode( shapeModel: AbstractShapeModel, editor ) {
        // Create new node
        let title = '';
        let details = '';
        let editable = true;
        const textModel = shapeModel.primaryTextModel;
        const hbText = ( textModel as any ).handlebarsReplacedText;
        if ( hbText ) {
            details = TiptapHelper
                    .convertCarotaToTiptapHtml( textModel.content, editor.schema );
            editable = false;
            title = hbText;
        } else if ( textModel && textModel.rendering === 'tiptapCanvas' ) {
            if ( !TiptapHelper.isUniformFormatWithNoLineBreaks( textModel.html )) {
                details = textModel.html;
                editable = false;
            }
            title = textModel?.plainText || '';
        } else {
            if ( textModel?.content.length > 1 ) {
                title = textModel.plainText;
                details = TiptapHelper
                    .convertCarotaToTiptapHtml( textModel.content, editor.schema );
                editable = false;
            }
            title = textModel?.plainText || '';
        }
        if ( !details ) {
            details = '<p></p>';
        }
        const titleNode = document.createTextNode( title );
        const detailsNode = document.createElement( 'div' );
        detailsNode.innerHTML = details;

        const primaryTextNode = document.createElement( 'primary-text-node' );
        primaryTextNode.setAttribute( 'showbutton', `${!editable}` );

        const summary = document.createElement( 'summary' );
        const dir = getTextDirection( title ) || 'ltr'  ;
        summary.setAttribute( 'inactive', `${!editable}` );
        summary.setAttribute( 'dir', dir );
        summary.setAttribute( 'contenteditable', `${editable}` );
        summary.setAttribute( 'contenteditable', `${editable}` );
        summary.appendChild( titleNode );

        primaryTextNode.appendChild( summary );
        primaryTextNode.appendChild( detailsNode );


        return {
            content: primaryTextNode.outerHTML,
            title,
            details,
            editable,
        };
    }

    protected manageShapeDeletes() {
        return this.ll.forCurrentObserver( false ).pipe(
            switchMap( l => merge(
                l.getDiagramOnce().pipe( tap( d =>  {
                    this.cleanUpTextNodes( d.id,  Object.keys( d.shapes ));
                })),
                l.getDiagramChanges().pipe(
                    map( c =>  {
                        if ( c.source !== 'init' && c.split && c.split.shapes ) {
                            Object.keys( c.split.shapes )
                                .map( key => {
                                    const m = c.split.shapes[ key ];
                                    if (  m.$unset as any === true ) {
                                        this.removeShapeTextNodes( c.model.id, key );
                                    }
                                });
                        }
                    }),
                ),
            )),
        );
    }

    protected removeShapeTextNodes( diagramId, shapeId ) {
        const elements = document.querySelectorAll(
            `tiptap-editor-component[id="${diagramId}"].notes div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"]` ) || [];
        elements.forEach(( el: HTMLElement ) => el.remove());
    }

    protected cleanUpTextNodes( diagramId, shapeIds: string[]) {
        const elements = document.querySelectorAll(
            `tiptap-editor-component[id="${diagramId}"].notes div[data-type="tiptap-child-editor-node"]` ) || [];
        elements.forEach(( el: HTMLElement ) => {
            if ( !shapeIds.includes( el.getAttribute( 'shapeId' ))) {
                el.remove();
            }
        });
    }


    /**
     * After executing this method, only tiptap child editor nodes are left in the document.
     * @param diagramId
     * @param shapeIds
     */
    protected cleanUpDocument( diagramId ) {
        const elements = document.querySelectorAll(
            `tiptap-editor-component[id="${diagramId}"].notes .prose > *` ) || [];
        elements.forEach(( el: HTMLElement ) => {
            if ( !el.classList.contains( 'tiptap-child-editor' )) {
                el.remove();
            }
        });
    }

    public static removeDuplicates( diagramId, shapeId ) {
        const elements = document.querySelectorAll(
            `tiptap-editor-component[id="${diagramId}"].notes div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"]` ) || [];
        elements.forEach(( el: HTMLElement, i ) => {
            if ( i > 0 ) {
                el.remove();
            }
        });

        const primaryTextNodes = document.querySelectorAll(
            `tiptap-editor-component[id="${diagramId}"].notes div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"] div[data-type="primaryTextNode"]` ) || [];
        primaryTextNodes.forEach(( el: HTMLElement, i ) => {
            if ( i > 0 ) {
                el.remove();
            }
        });
    }

    /**
     * Destroys the toolbar
     */
    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }


    /**
     * Create RichTextEditorUIC component and set initial value
     */
    protected createTiptap( diagramId: string, waitForInit = true ): Observable<ComponentRef<TiptapEditor>> {
        const itemRefTiptap: ComponentRef<TiptapEditor> = this.makeComponent( TiptapEditor );
        const tipTapEditorCmp = itemRefTiptap.instance;
        tipTapEditorCmp.id = 'notes';
        tipTapEditorCmp.diagramId = diagramId;
        tipTapEditorCmp.transformPastedHTML = v => this.transformPastedHTML( v );
        this.insert( this.viewContainer, itemRefTiptap );
        itemRefTiptap.changeDetectorRef.detectChanges();
        this.subs.push( this.escapeSub( tipTapEditorCmp ));
        const sub = tipTapEditorCmp.change.subscribe( data => {
            this.onChildNodeviewChange( diagramId, data  );
        });
        this.subs.push( sub );
        if ( !waitForInit ) {
            return of( itemRefTiptap );
        }
        return this.waitForInitTiptap( itemRefTiptap );
    }

    private waitForInitTiptap( itemRefTiptap: ComponentRef<TiptapEditor> ) {
        return itemRefTiptap.instance.localSynced.pipe(
            filter( v => !!v ),
            switchMap(() => itemRefTiptap.instance.editorSubject ),
            filter( v => !!v ),
            take( 1 ),
            map(() => itemRefTiptap ),
        );
    }

    /**
     * Update text on Escape
     * @param tipTapEditorCmp
     * @protected
     */
    protected escapeSub( tipTapEditorCmp ) {
        return fromEvent( tipTapEditorCmp.editorElement.nativeElement, 'keydown' )
            .subscribe(( e: KeyboardEvent ) => {
                if ( e.keyCode === KeyCode.Escape ) {
                    tipTapEditorCmp.change.next( tipTapEditorCmp.getChildEditorHTML());
                }
            });
    }

    protected transformPastedHTML( html: string ) {
        const el = document.createElement( 'div' );
        el.innerHTML = html;
        const elements = el.querySelectorAll( 'div[data-type="detailsContent"]' );
        elements.forEach( elm => {
            elm.removeAttribute( 'data-type' );
            const innerElements = elm.querySelectorAll( '*[style]' );
            innerElements.forEach(( innerElement: any ) => {
              innerElement.style.removeProperty( 'text-align' );
            });
        });
        return el.innerHTML;
    }

    /**
     * Dispatches the changeShapeDataItems command to update data items
     */
     protected onChildNodeviewChange( diagramId: string, change: { shapeId: string, html: any }) {
        const shapeId = change?.shapeId;
        if ( shapeId ) {
            this.commandSvc.dispatch( DiagramCommandEvent.changeShapeDataItems, diagramId, {
                [shapeId]: {
                    description: {
                        value: change.html,
                    },
                },
            });
        }
    }

    /**
     * Updates the tiptap editor for notes sections corresponds to the given modifier
     * @param change
     */
    public static updateDocumentByModifier( diagramId: string, c: IModifier ) {
        // TODO: Show conflicts resolver
        const split = splitModifier( c );
        Object.keys( split.shapes || {})
            .forEach( shapeId => {
                if ( Object.keys( split.shapes[ shapeId ].$set || {}).includes( 'data.description.value' )) {
                    const value = split.shapes[ shapeId ].$set[ `data.description.value` ];
                    TiptapDocumentsManager.updateDocument( diagramId, shapeId, value );
                }
            });
    }

    /**
     * Replaces the notes section by given html content
     * @param change
     */
     public static updateDocument( diagramId: string, shapeId: string, html: string ) {
        const { node, from, to, editor } = TiptapDocumentsManager
            .getChildEditorNode( diagramId, shapeId );
        if (  editor ) {
            const current = getHTMLFromFragment( node.content, editor.schema );
            if ( html !== current ) {
                editor.chain()
                .focus()
                .insertContentAt({ from, to }, html )
                .blur()
                .run();
            }
        }
    }

    public static getChildEditorNode( diagramId: string, shapeId: string ):
    { domNode: HTMLElement, node?: any, from?: number, to?: number, editor?: Editor } {
        const editor = TiptapDocumentsManager.documents[ diagramId ]?.instance?.editor;
        if ( !editor ) {
            return { domNode: null };
        }
        const domNode: HTMLElement = editor.view.dom.querySelector(
            `tiptap-editor-component[id="${ diagramId }"].notes div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"]`,
        );
        if ( domNode ) {
            // const editor = TiptapDocumentsManagerShapeText.documents[ diagramId ].instance.editor;
            const pos = editor.view.posAtDOM( domNode as HTMLElement, 0 );
            const $pos = editor.view.state.doc.resolve( pos );
            for ( let d = $pos.depth; d > 0; d-- ) {
                const node = $pos.node( d );
                if ( node.type.name === 'tiptapChildEditor' ) {
                    return { domNode, node, from: pos, to: pos + node.nodeSize - 3, editor };
                }
            }
        }
        return { domNode };
    }

    public static getPrimaryTextNode( diagramId: string, shapeId: string ):
    { domNode: HTMLElement, node?: any,
        from?: number, to?: number, editor?: Editor, title?: string, details?: string } {
        const editor = TiptapDocumentsManager.documents[ diagramId ].instance.editor;
        const domNode: HTMLElement = editor.view.dom.querySelector(
            `tiptap-editor-component[id="${ diagramId }"].notes div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"] div[data-type="primaryTextNode"]`,
        );
        if ( domNode ) {
            // const editor = TiptapDocumentsManagerShapeText.documents[ diagramId ].instance.editor;
            const pos = editor.view.posAtDOM( domNode as HTMLElement, 0 );
            const $pos = editor.view.state.doc.resolve( pos );
            for ( let d = $pos.depth; d > 0; d-- ) {
                const node = $pos.node( d );
                if ( node.type.name === 'primaryTextNode' ) {
                    const title = domNode.querySelector( 'summary' ).textContent;
                    const detailsElement = domNode.querySelector( 'div[data-type="detailsContent"]' );
                    let details = '';
                    if ( detailsElement.textContent.trim()) {
                        details = domNode.querySelector( 'div[data-type="detailsContent"]' ).innerHTML;
                    }
                    return { domNode, node, from: pos, to: pos + node.nodeSize - 3, editor, title, details };
                }
            }
        }
        return { domNode };
    }

}

import { empty, defer } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CommandEvent, AbstractDiagramCommandEvent, AbstractProjectCommandEvent, AbstractEDataCommandEvent } from './command-event';
import { Logger } from '../logger/logger.svc';
import { CommandScenario } from './command-scenario.enum';
import { ICommandSequenceProgress } from './command-progress.i';
import { AbstractCommandScenario } from './scenario/abstract-command-scenario';
import { EventCollector, EventIdentifier, IEventLog } from '../logger/event-collector.svc';
import { JsonUtil } from '../data/json-util';
import { Flags } from '../flags';

/**
 * This is an Angular service that will be the interface to the
 * Command Framework. Anyone can dispatch a command by injecting this
 * service. A valid {@link CommandEvent} which is already mapped to one
 * or more commands will trigger the mapped commands in the desired
 * manner {@see CommandMaper}. Commands can be mapped parallel or sequential
 * This service ensures the mapped commands are exeucted.
 *
 * See the <code>dispatch</code> method for more details. The result of
 * dispatching a command is a {@link Observable} which can be used to track
 * the success or faliur of command.
 *
 * @author  Gobiga
 * @since   2016-03-24
 */

@Injectable()
export class CommandService {

    /**
     * Command events that are dispatched.
     */
    public dispatchedEvents: Subject<CommandEvent> = new Subject();

    /**
     * Command events are queued processed synchronously.
     */
    protected eventQueue: Subject<Observable<any>>;

    constructor(
        protected log: Logger,
        protected injector: Injector,
    ) {
        this.eventQueue = new Subject();
        this.processQueue();
    }

    /**
     * This is the primary API of the command framework. When an {@link CommandEvent} is
     * dispatched it queues the command sequence that will be executed, associated
     * to the CommandEvent. This association should have been done previously using
     * the {@link CommandMapper} service. If no command is associated to the event, an
     * error will occur.
     *
     * This returns an observable with the progress of the command along with data and resultData.
     * When a execution steps completes the observable will be completed
     *
     * @param   event   Name of the event that will trigger a command sequence
     * @param   data    Data that has to be passed to the command.This should contain {diagramId} if
     *                  command implements {@link IDiagramCommand} or should contain {projectId} if
     *                  command implements {@link IProjectCommand} or should contain {eDataId} if
     *                  command implements {@link IEDataCommand}
     * @return  The result is a {@link Observable} which can be used to track the
     *          success or faliur of the event.
     */
    public dispatch(
        event: AbstractDiagramCommandEvent,
        diagramId?: string,
        data?: any ): Observable<any>;
    public dispatch(
        // tslint:disable-next-line:unified-signatures
        event: AbstractProjectCommandEvent,
        projectId?: string,
        data?: any ): Observable<any>;
    public dispatch(
        // tslint:disable-next-line:unified-signatures
        event: AbstractEDataCommandEvent,
        eDataId?: string,
        data?: any ): Observable<any>;
    public dispatch( event: CommandEvent, data?: any ): Observable<any>;
    public dispatch( event: CommandEvent, ...args: any[]): Observable<any> {

        let data: any;
        let resourceId: string;
        if ( args.length === 2 ) {
            [ resourceId, data ] = args;
        } else {
            [ data ] = args;
        }
        this.dispatchedEvents.next( event );
        return this.executeQueued( event, CommandScenario.EXECUTE, data, resourceId );
    }

    /**
     * This is the API which can be used to preview commands. Changes made by commands while
     * in this mode will be temporary. Example: Model changes will not be sent to the server
     * and they can also be reset easily.
     *
     * @param   event   Name of the event that will trigger a command sequence
     * @param   data    Data that has to be passed to the command.This should contain {diagramId} if
     *                  command implements {@link IDiagramCommand} or should contain {projectId} if
     *                  command implements {@link IProjectCommand} or should contain {eDataId} if
     *                  command implements {@link IEDataCommand}
     * @return  The result is a {@link Observable} which can be used to track the
     *          success or faliur of the event.
     */
    public preview(
        event: AbstractDiagramCommandEvent,
        diagramId?: string,
        data?: any ): Observable<any>;
    public preview(
        // tslint:disable-next-line:unified-signatures
        event: AbstractProjectCommandEvent,
        projectId?: string,
        data?: any ): Observable<any>;
    public preview(
        // tslint:disable-next-line:unified-signatures
        event: AbstractEDataCommandEvent,
        eDataId?: string,
        data?: any ): Observable<any>;
    public preview( event: CommandEvent, data?: any ): Observable<any>;
    public preview( event: CommandEvent, ...args: any[]): Observable<any> {
        let data: any;
        let resourceId: string;
        if ( args.length === 2 ) {
            [ resourceId, data ] = args;
        } else {
            [ data ] = args;
        }
        return this.executeQueued( event, CommandScenario.PREVIEW, data, resourceId );
    }

    /**
     * This is the primary API for undoing a change done by a previous command event.
     * This will undo model changes made by all commands in the said event.
     *
     * @param eventId The id of the event that is being undone
     * @param resourceId - Resource id
     * @return - An observable which emits the progress of the undo operation and completes.
     */
    public undo( eventId: string, resourceId: string ): Observable<any> {
        const event = new CommandEvent( eventId );
        return this.executeQueued( event, CommandScenario.UNDO, undefined, resourceId );
    }

    /**
     * This is the primary API for redoing a change made by a previous undo event.
     * This will redo all changes made by all commands in the said event.
     *
     * @param eventId The id of the event that is being re-done
     * @param resourceId - Resource id
     * * @return - An observable which emits the progress of the redo operation and completes.
     */
    public redo( eventId: string, resourceId: string ) {
        const event = new CommandEvent( eventId );
        return this.executeQueued( event, CommandScenario.REDO, undefined, resourceId );
    }

    /**
     * The collab method is used to execute commands that are received from other known clients.
     * These commands have already been executed and the sequence simply simulates the same action
     * on this client to keep them in sync - realtime. To run the collab scenario, the command name
     * is required.
     * @param commandName - name of command
     * @param resourceId - resource id
     * @param data - data passed into the command
     * @param resultData - result data
     */
    public collab( commandName: string, resourceId?: string, data?: any, resultData?: any ): Observable<any> {
        const collabScenario = this.injector.get( this.getServiceName( CommandScenario.COLLAB ));
        return collabScenario.executeUnmapped( commandName, resourceId, data, resultData );
    }

    /**
     * Allows executing a command without mapping or dispatching a command event.
     * Commands are executed under the Execute command scenario.
     *
     * Although a command does not need to be mapped to a command event, they still need
     * to be registered with the CommandMapper.
     * example -
     * <code> this.mapper.registerCommand( CommandClass as any )</code>
     * @param commandName - name of command ( string )
     * @param resourceId - resource id
     * @param data - data passed into the command
     * @param resultData - result data
     */
    public execute( commandName: string, resourceId?: string, data?: any, resultData?: any ): Observable<any> {
        const executeScenario = this.injector.get( this.getServiceName( CommandScenario.EXECUTE ));
        return executeScenario.executeUnmapped( commandName, resourceId, data, resultData );
    }

    /******************************************************
     * Functionality for managing the command event queue
     ******************************************************/

    /**
     * Starts processing the event queue.
     */
    protected processQueue() {
        this.eventQueue.pipe(
            concatMap( observable => observable ))
            .subscribe();
    }

    /**
     * Executes a command event by pushing it to the command event queue.
     * The command event is executed via the relevant command scenario service.
     * However, the execute method of this service is not run untill
     * the command event is invoked by the queue (when the event queue
     * subscribes to the queued observable).
     * @param event - command event to be queued
     * @param scenario - scenario the command would be executed
     * @param data - data for the command event
     * @param resourceId - resource id
     * @return observable which emits the progress of the command event execution.
     */
    protected executeQueued(
        event: CommandEvent,
        scenario: CommandScenario,
        data: any,
        resourceId: string,
     ) {
        const listener = new Subject();
        const observable = defer(() => {
            const scenarioSvc: AbstractCommandScenario = this.injector.get( this.getServiceName( scenario ));
            const eventObservable = scenarioSvc.execute( event, data, resourceId );
            if ( scenario === CommandScenario.EXECUTE ||
                ( scenario === CommandScenario.PREVIEW && Flags.get( 'LOG_PREVIEW_CHANGES' ))) {
                EventCollector.log({
                    message: EventIdentifier.COMMAND_EVENT_STARTED,
                    event,
                    scenario,
                    data: data ? JsonUtil.clone( data ) : /* istanbul ignore next */ data,
                    resourceId,
                });
            }
            const queueObservable = new Subject();

            /**
             * The event observable should be subscribed only once to make sure the command
             * sequence is run only once. The progress emitted by the event observable must
             * be forwarded to the observable returned to the user. The progress is also used
             * to check whether an asynchronous step has started to complete the queue observable.
             * When the queue observable completes, the queue will start the next command sequence.
             */
            eventObservable.pipe(
                catchError( err => {
                    listener.error( err );
                    queueObservable.complete();
                    if ( scenario === CommandScenario.EXECUTE ||
                        ( scenario === CommandScenario.PREVIEW && Flags.get( 'LOG_PREVIEW_CHANGES' ))) {
                        EventCollector.log({
                            message: EventIdentifier.COMMAND_EVENT_ERRORED,
                            event,
                            scenario,
                            data: data ? JsonUtil.clone( data ) : /* istanbul ignore next */ data,
                            resourceId,
                        });
                    }
                    return empty();
                }))
                .subscribe({
                    next( progress: ICommandSequenceProgress ) {
                        listener.next( progress );
                        if ( progress.stepIsAsync ) {
                            queueObservable.complete();
                        }
                    },
                    complete() {
                        listener.complete();
                        queueObservable.complete();
                        if ( scenario === CommandScenario.EXECUTE ||
                            ( scenario === CommandScenario.PREVIEW && Flags.get( 'LOG_PREVIEW_CHANGES' ))) {
                            EventCollector.log({
                                message: EventIdentifier.COMMAND_EVENT_COMPLETED,
                                event,
                                scenario,
                                data: data ? JsonUtil.clone( data ) : /* istanbul ignore next */ data,
                                resourceId,
                            });
                        }
                    },
                });

            return queueObservable;
        });
        if ( scenario === CommandScenario.EXECUTE ||
                ( scenario === CommandScenario.PREVIEW && Flags.get( 'LOG_PREVIEW_CHANGES' ))) {
            EventCollector.log({
                message: EventIdentifier.COMMAND_EVENT_QUEUED,
                event,
                scenario,
                data: data ? JsonUtil.clone( data ) : data,
                resourceId,
            });
        }
        this.eventQueue.next( observable );
        return listener;
    }

    private getServiceName( scenario: CommandScenario ) {
        return scenario + 'Scenario';
    }
}

type CommandEventIdentifier =
    EventIdentifier.COMMAND_EVENT_STARTED | EventIdentifier.COMMAND_EVENT_QUEUED |
    EventIdentifier.COMMAND_EVENT_COMPLETED | EventIdentifier.COMMAND_EVENT_ERRORED;
export interface ICommandEventLog extends IEventLog {
    message: CommandEventIdentifier;
    ts: number;
    event: CommandEvent;
    scenario: CommandScenario;
    data: any;
    resourceId: string;
}
